import { Injectable } from '@angular/core';
import { WorkspaceGuardService } from '../../core/auth/workspace.guard.service';
import { Subject } from 'rxjs';

@Injectable()
export class TabTitleService {
  tab$ = new Subject()
  constructor(private workspaceService: WorkspaceGuardService) { }

  public setTabTitle(title: any): string {
    const workspace = this.workspaceService.getCurrentWorkspace();
    const workspaceName = workspace && workspace.Name ? ' - ' + workspace.Name : '';
    this.tab$.next(title)
    return title + workspaceName;
  }
}

import { Environment } from './model';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: false,
  version: 'SHTest ' + require('../../package.json').version,
  // signapi_url: 'https://test-api.mcttechnology.com/signin/v1/',
  // apigateway_url: 'https://ustest-interapi.carecloud.io/gateway/v1/', // ustest
  // apigateway_url: 'http://10.211.55.4/carecloud-gateway/',
  // apigateway_url: 'https://shtest-gateway.mcttechnology.cn/v3/', // Shtest
  app_id: 'carewait',
  app_secret: 'SxWH7UAufkXAY4JRrYep',
  apigateway_url: 'https://shtest-gateway.mcttechnology.cn/v3_new/', // Shtest
  apigateway_key: 'testkey',
  careportal_url: 'https://shtest-carecloud.mcttechnology.cn',
  mctcarewait_url: 'https://shtest-serviceportal.mcttechnology.cn/MCTCareWait',
  familyportal_url: 'http://shtest-family.mcttechnology.cn',
  showDevModule: true,
  MordenStripePublishKey: 'pk_test_304auB7661WhShFy6eiTKNIqtRQabZ3lhnuNO8yBtLyxocRm9jsCiNQGT0CfmQD60OVK78YCwJ9gOeh1P2gotRO1I00bwYwueqd',
  SubscriptionStripeKey: 'pk_04auKcY02GHaWEH0EuSNt5v4YyZrW',
  StripeConnectAccountId: 'acct_19dBQ1CF8FArTWww',
  SubscriptionStripePriceId: 'price_0HRxrJ04auB7661WIBF8pQiX',
  PlaidEnvironments: 'sandbox',
  PlaidPublishableKey: '0e6f67e5e9baf0134db3852ab7b6fd',
  // message chat config
  ChatBaseUrl: 'https://test-api-gateway.mcttechnology.com/message',
  MsgGatewayBaseUrl: 'https://shtest-api-gateway.mcttechnology.cn',
  XApiKey: 'bdDW1bdrtu1OvsLuf5T4Oa1UfMQhkq4Z5vaXPdEe',
  ChatKey: 'pub-c-8359d235-1ec5-40ce-8eda-20aed3d598c6,sub-c-68aafea2-53a2-11ea-80a4-42690e175160',
  // ChatKey: 'pub-c-215c33a7-d3d9-45b7-97fa-e6b432a30501,sub-c-62244d92-c2af-11e9-ba36-02e0e0e0a6e1',
  // tslint:disable-next-line:max-line-length
  ChatEngineKey:
    'pub-c-4ac38c6f-d4f5-4f65-baa6-ae387dbc4183,sub-c-69375274-57f7-11e8-a697-1afc57e8b539,sec-c-YzMyYzFiNzItNGQzNy00YWFhLWIxZjQtNmM1OTUxNTg3ZjZh',

  ENV_PROVIDERS: [],
  googleAnalytics: {
    domain: 'none',
    trackingId: 'UA-105702940-4', // replace with your Tracking Id
  },
  // saml
  samlSetting: {
    samlSsoUrl: 'https://shtest-sso.mcttechnology.cn/Saml/InitiateSingleSignOn',
    samlSloUrl: 'https://shtest-sso.mcttechnology.cn/Saml/InitiateSingleLogout',
    samlAuthUrl: 'https://shtest-sso.mcttechnology.cn/Saml/Token',
  },
  samlStanford: 'https://ustest-sso.carecloud.io/Saml/ExternalSingleSignOn?idp=stanford',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model';
import { LogService } from '../log/log.service';

// import { Base64 } from 'base64-js';
import { JsonServiceClient } from '@servicestack/client';

const baseUrl = environment.apigateway_url;
const appId = environment.app_id;

export class AuthResponse {
  Token: string;
  IsSuccess: boolean;
  ErrCode: number;
  ErrMsg: string;
  Data: User;
}
@Injectable()
export class AuthAppService {
  public apiToken: string;
  public apiReady: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private logger: LogService) {}

  public getAuthClient(): JsonServiceClient {
    const url = baseUrl + 'cc_auth';
    const jc = new JsonServiceClient(url);
    return jc;
  } // workspace module initial
  public async getApiToken(refresh = false) {
    this.logger.debug(`Login with auto app`);
    // get service cache;
    if (this.apiToken) {
      return this.apiToken;
    }

    let token = await this.getNewAppToken();
    this.setCurrentToken(token);
    return token;
  }

  public async getNewAppToken() {
    const id = environment.app_id;
    const code = environment.app_secret;
    const client = this.getAuthClient();
    client.setCredentials(id, code);
    const req = {
      Provider: 'appverify',
      createResponse() {
        return new AuthResponse();
      },
    };
    const data = await client.postToUrl('/', req);
    if (data.IsSuccess) {
      return data.Token;
    } else {
      throw new Error(data.ErrMsg);
    }
  }

  private setCurrentToken(token: string): void {
    this.apiReady.next(token);
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class CacheService {
  data: any = {};
  cacheData: any = {};
  private cacheType = 1; // 1: localStorage; 2: CacheMemomery
  private cacheTime = 30 * 60; // 多少秒，清空缓存

  constructor() {
    const key = 'dataCache';
    this.cacheData = this.data[key];
  }

  // public getReportCacheService() {
  //     return this.cacheData['reportCache'];
  // }

  public setCacheData(key, data, type = 1) {
    if (this.cacheType === 1 && type === 1) {
      const option = new LocalStorageSaveOptions();
      option.data = data;
      option.key = key;
      option.expirationMins = this.cacheTime;
      this.save(option);
    } else {
      if (!this.cacheData) {
        this.cacheData = {};
      }
      this.cacheData[key] = data;
    }
  }

  public getCacheData(key, type = 1) {
    if (this.cacheType === 1 && type === 1) {
      return this.load(key);
    } else {
      return this.cacheData ? this.cacheData[key] : null;
    }
  }

  public clearCacheData() {
    this.cleanLocalStorage();
    this.cacheData = {};
    // if (this.cacheType === 1 && type === 1) {

    // } else {
    //     this.cleanLocalStorage();
    //     return this.cacheData = {};
    // }
  }

  public clearCacheDataByKey(key) {
    this.remove(key);
    if (this.cacheData) {
      this.cacheData[key] = null;
    }
    // if (this.cacheType === 1) {
    //     return this.remove(key);
    // } else {
    //     return this.cacheData[key] = null;
    // }
  }

  // source data from : https://dev.to/maurogarcia_19/client-side-caching-with-angular-2i6l
  private keyProfix = 'mCache_';
  save(options: LocalStorageSaveOptions) {
    // Set default values for optionals
    options.expirationMins = options.expirationMins || 0;
    // Set expiration date in miliseconds
    const expirationMS = options.expirationMins !== 0 ? options.expirationMins * 1000 : 0;

    const record = {
      value: typeof options.data === 'string' ? options.data : JSON.stringify(options.data),
      expiration: expirationMS !== 0 ? new Date().getTime() + expirationMS : null,
      hasExpiration: expirationMS !== 0 ? true : false,
    };
    const key = this.keyProfix + options.key;
    // console.log('==== save data from cache', key, options.data);
    localStorage.setItem(key, JSON.stringify(record));
  }

  load(key: string) {
    const k = this.keyProfix + key;
    // Get cached data from localstorage
    const item = localStorage.getItem(k);
    if (item !== null) {
      const record = JSON.parse(item);
      const now = new Date().getTime();
      // Expired data will return null
      if (!record || (record.hasExpiration && record.expiration <= now)) {
        return null;
      } else {
        try {
          if (record.value) {
            const data = JSON.parse(record.value);
            // console.log('==== get data from cache', k, data);
            return data;
          }
        } catch (e) {
          // // console.log('====', e, key);
          return null;
        }
      }
    }
    return null;
  }

  remove(key: string) {
    const k = this.keyProfix + key;
    localStorage.removeItem(k);
  }

  cleanLocalStorage() {
    let removeLocalStorage = [];
    if (localStorage && localStorage.length > 0) {
      for (var i = 0, len = localStorage.length; i < len; ++i) {
        const k = localStorage.key(i);
        if (k && k.indexOf(this.keyProfix) > -1) {
          removeLocalStorage.push(k);
        }
        // // console.log(localStorage.getItem(localStorage.key(i)));
      }
      // localStorage.clear()
      removeLocalStorage.forEach(item => {
        localStorage.removeItem(item);
      });
    }
  }
}

@Injectable()
export class ReportCacheService extends CacheService {
  cacheData: any = {};
  constructor() {
    super();
    const key = 'reportCache';
    this.cacheData = this.data[key];
  }
}

@Injectable()
export class EntityCacheService extends CacheService {
  cacheData: any = {};
  constructor() {
    super();
    const key = 'entityCache';
    this.cacheData = this.data[key];
  }
}

export class LocalStorageSaveOptions {
  key: string;
  data: any;
  expirationMins?: number = 15;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IReturn<T> {
  createResponse(): T;
}

export class BaseRequest implements IReturn<{}> {
  UserId: number;
  CustomerId: number;
  createResponse() {
    return {};
  }
  // Language: string;
}

export class BaseResponse {
  IsSuccess: boolean;
  ErrCode: number;
  ErrMsg: string;
}

export class Response<T> extends BaseResponse {
  Data: T;
}

export class BaseCareWaitRequest implements IReturn<{}> {
  UserId: number;
  CustomerId: number;
  RequestCustomerId: number;
  RequestWorkspaceId: any;
  createResponse() {
    return {};
  }
  // Language: string;
}

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'get',
})
export class GetPipe implements PipeTransform {
  transform(value: unknown, path: any, defaultValue?: any) {
    return _.get(value, path, defaultValue);
  }
}

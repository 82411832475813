import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: string, length?: number) {
    if (length && length > 0) {
      if (value && value.length > length) {
        value = value.substring(0, length);
        value += ' ...';
      }
    }
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'czone' })
export class CZonePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, isExport: boolean = false) {
    // this.translate.get('SUBSCRIPTION')['value']
    if (value) {
      const offset = -new Date().getTimezoneOffset() / 60;
      const hour = Math.floor(offset);
      const min = (Math.abs(offset) % 1) * 60;
      const time =
        (hour >= 0 ? '+' : '-') +
        ('000' + Math.abs(hour)).substr(-2) +
        ':' +
        ('000' + min).substr(-2);

      // const date = '<span style="word-break: break-all;">' + value + '</span>';
      if (!isExport) {
        const z =
          '<span style="padding-left:5px; word-break: break-all; ">(UTC' + time + ')</span>';
        return this.sanitizer.bypassSecurityTrustHtml(value + z);
      } else {
        return value + ' (UTC' + time + ')';
      }
    }
  }
}

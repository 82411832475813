import {
  Directive,
  Input,
  ViewContainerRef,
  ChangeDetectorRef,
  TemplateRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

import {
  NgxPermissionsDirective,
  NgxPermissionsService,
  NgxPermissionsConfigurationService,
  NgxRolesService,
} from 'ngx-permissions';

@Directive({ selector: '[appPermission]' })
export class AppWithPermissionDirective extends NgxPermissionsDirective implements OnChanges {
  @Input() appPermission: string | string[];
  @Input() appPermissionThen: TemplateRef<any>;
  @Input() appPermissionElse: TemplateRef<any>;

  constructor(
    permissionsService: NgxPermissionsService,
    configurationService: NgxPermissionsConfigurationService,
    rolesService: NgxRolesService,
    viewContainer: ViewContainerRef,
    changeDetector: ChangeDetectorRef,
    templateRef: TemplateRef<any>,
  ) {
    // prettier-ignore
    // @ts-ignore
    super(permissionsService, configurationService, rolesService, viewContainer, changeDetector, templateRef);
  }

  override ngOnChanges(changes: SimpleChanges) {
    const change = changes.appPermission;
    // this.ngxPermissionsOnly = change.currentValue;
    const only = change.currentValue;
    const exc = [];
    if (only) {
      let rpt = false;
      let admin = false;
      if (only instanceof Array) {
        only.forEach(item => {
          if (item.startsWith('rpt')) {
            rpt = true;
          }
          if (item.startsWith('sp.ct.')) {
            admin = true;
            exc.push(item);
            if (item.startsWith('sp.ct.readonly.')) {
              exc.push('sp.ct.readonly.forall');
            } else if (item.startsWith('sp.ct.normal.')) {
              exc.push('sp.ct.normal.forall');
            }
          } else {
            exc.push('sp.ct.readonly.' + item);
            exc.push('sp.ct.normal.' + item);
          }
        });
      } else {
        if (only.startsWith('rpt')) {
          rpt = true;
        }
        if (only.startsWith('sp.ct')) {
          admin = true;
          exc.push(only);
        } else {
          exc.push('sp.ct.readonly.' + only);
          exc.push('sp.ct.normal.' + only);
        }
      }
      if (!admin && rpt) {
        exc.push('sp.ct.readonly.forall.report');
        exc.push('sp.ct.normal.forall.report');
      } else if (!admin) {
        exc.push('sp.ct.readonly.forall');
        exc.push('sp.ct.normal.forall');
      }
      exc.push('sp.ct.workspace.administrator');
      this.ngxPermissionsOnly = exc;
    }
    // this.ngxPermissionsExcept = 'sp.pm.app.nds';
    if (this.appPermissionElse) {
      this.ngxPermissionsElse = this.appPermissionElse;
    }
    if (this.appPermissionThen) {
      this.ngxPermissionsThen = this.appPermissionThen;
    }
  }
}

import { Injectable } from '@angular/core';

import notify from 'devextreme/ui/notify';
import { alert, confirm, custom } from 'devextreme/ui/dialog';
@Injectable()
export class NotifyService {
  constructor() {}

  showInfo(text: string, time = 600): void {
    notify(text, 'info', time);
  }

  showWarning(text: string, time = 3000): void {
    notify(text, 'warning', time);
  }

  showSuccess(text: string, time = 3000): void {
    notify(text, 'success', time);
  }

  showError(text: string, time = 3000, title: string = 'Error'): void {
    notify(text, 'error', time);
  }

  showAlert(text: string, title: string = 'Alert'): Promise<void> {
    return alert(text, title);
  }

  showConfirm(text: string, title: string = 'Confirm'): Promise<boolean> {
    return confirm(text, title);
  }

  showDialog(text: string, title: string, buttons: any[]): any {
    const options = {
      title: title,
      message: text,
      buttons: buttons,
    };
    return custom(options);
  }
}

/*
Wapper system log and ngx-logger
*/
import { Injectable } from '@angular/core';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../../../environments/environment';

@Injectable()
export class LogService {
  private level = environment.LoggerLevel;
  public enable: boolean;

  constructor(public logger: NGXLogger) {
    this.enable = true;
    switch (this.level) {
      case 'Trace':
        logger.updateConfig({ level: NgxLoggerLevel.TRACE });
        break;
      case 'Debug':
        logger.updateConfig({ level: NgxLoggerLevel.DEBUG });
        break;
      default:
        logger.updateConfig({ level: NgxLoggerLevel.ERROR });
    }
  }

  trace(message: any, ...additional: any[]): void {
    if (this.enable) {
      this.logger.trace(message, ...additional);
    }
  }
  debug(message: any, ...additional: any[]): void {
    if (this.enable) {
      this.logger.debug(message, ...additional);
    }
  }
  info(message: any, ...additional: any[]): void {
    if (this.enable) {
      this.logger.info(message, ...additional);
    }
  }
  // log(message: any, ...additional: any[]): void {
  //     if (this.enable) {
  //         this.logger.log(message, additional);
  //     }
  // }
  warn(message: any, ...additional: any[]): void {
    if (this.enable) {
      this.logger.warn(message, ...additional);
    }
  }
  error(message: any, ...additional: any[]): void {
    if (this.enable) {
      this.logger.error(message, ...additional);
    }
  }
}

import { NgModule, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';
@Pipe({ name: 'u2ldate' })
export class U2ldatePipe implements PipeTransform {
  transform(value: string, format: string = '', today: boolean = false) {
    const invalidDateMessage = ' '; // Invalid Date
    if (!value) {
      return invalidDateMessage;
    }
    const dstring = value.replace(/0/gim, '');
    if (dstring.indexOf('1/1/1') >= 0) {
      return invalidDateMessage;
    }
    const fmt = format ? format.split(',') : ['MM/DD/YYYY hh:mm:ss A', ''];
    const specifiedFormat = fmt[0];
    const utcDateTimeFormat = fmt[1];
    const regexMatchForIso8601Date = /[0-9]T[0-9]/;

    let utcDateTime = moment();
    if (value.toLowerCase().indexOf('date') > -1) {
      value = moment(value).format('MM/DD/YYYY hh:mm:ss A');
    }
    // if (regexMatchForIso8601Date.test(value)) {
    //     utcDateTime = moment(value);
    // } else { }
    value = value;
    if (utcDateTimeFormat) {
      utcDateTime = moment.utc(value, utcDateTimeFormat).local();
    } else {
      utcDateTime = moment.utc(value).local();
    }

    if (!utcDateTime.isValid()) {
      return invalidDateMessage;
    } else {
      // debugger;
      if (today) {
        if (utcDateTime.format('YYYYMMDD') === moment().format('YYYYMMDD')) {
          return utcDateTime.format('hh:mm A');
        } else {
          if (fmt && fmt[0]) {
            return utcDateTime.format(fmt[0]);
          } else {
            return utcDateTime.format('MM/DD/YYYY');
          }
        }
      } else {
        return utcDateTime.format(specifiedFormat);
      }
    }
  }
}

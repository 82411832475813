{
  "name": "carewait",
  "version": "3.1.2425-25",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "modernizr": "modernizr -c modernizr-config.json -d src/modernizr.js",
    "buildshtest": "npm version prerelease && ng build --configuration=shtest && cross-var aws s3 mv s3://shtest-web.carecloud.io/$npm_package_name  s3://shtest-web.carecloud.io/backup/$npm_package_name/$npm_package_version --recursive  --profile shtest && cross-var aws s3 cp dist/browser/ s3://shtest-web.carecloud.io/$npm_package_name/ --metadata-directive REPLACE --exclude index.html --cache-control public,max-age=604800,immutable --recursive --acl public-read  --profile shtest && cross-var aws s3 cp dist/browser/ s3://shtest-web.carecloud.io/$npm_package_name/ --metadata-directive REPLACE --exclude * --include index.html --cache-control public,max-age=600,immutable --recursive --acl public-read  --profile shtest && aws cloudfront create-invalidation --distribution-id EXTGHB7Q6AMI5  --paths /* --profile shtest",
    "buildstaging": "npm version prerelease && ng build --configuration=staging && cross-var aws s3 mv s3://ustest-web.carecloud.io/$npm_package_name  s3://ustest-web.carecloud.io/backup/$npm_package_name/$npm_package_version --recursive  --profile staging && cross-var aws s3 cp ./dist/browser/   s3://ustest-web.carecloud.io/$npm_package_name/  --metadata-directive REPLACE --exclude index.html --cache-control public,max-age=604800,immutable --recursive --acl public-read --profile staging && cross-var aws s3 cp dist/browser/ s3://ustest-web.carecloud.io/$npm_package_name/ --metadata-directive REPLACE  --exclude * --include index.html --cache-control public,max-age=600,immutable --recursive --acl public-read  --profile staging  && aws cloudfront create-invalidation --distribution-id E1CNSR7RX7JNEB  --paths /*  --profile staging  && aws cloudfront create-invalidation --distribution-id E195FTII1QAF3Z  --paths /*  --profile staging",
    "buildprod": "npm version prerelease && ng build --configuration=production && cross-var aws s3 mv s3://web.carecloud.io/$npm_package_name  s3://web.carecloud.io/backup/$npm_package_name/$npm_package_version --recursive --profile prod && cross-var aws s3 cp ./dist/browser/   s3://web.carecloud.io/$npm_package_name/  --metadata-directive REPLACE --exclude index.html --cache-control public,max-age=604800,immutable  --recursive --acl public-read --profile prod  && cross-var aws s3 cp ./dist/browser/  s3://web.carecloud.io/$npm_package_name/  --metadata-directive REPLACE --exclude * --include index.html --cache-control public,max-age=600,immutable  --recursive --acl public-read --profile prod && aws cloudfront create-invalidation --distribution-id E1ZVNAYKNR05ZG  --paths /* --profile prod && aws cloudfront create-invalidation --distribution-id E1R8TK4YB9WIPG  --paths /* --profile prod",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.0",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@fortawesome/fontawesome-free": "5.5.0",
    "@ng-idle/core": "^14.0.0",
    "@ng-idle/keepalive": "^14.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@servicestack/client": ">=2.0.17 <2.1",
    "@stripe/stripe-js": ">=2.1.7 <3.0.0",
    "bootstrap": "4.6.2",
    "devextreme": "^23.1.6",
    "devextreme-angular": "^23.1.6",
    "exceljs": "^4.4.0",
    "file-saver": "^2.0.5",
    "jquery": "^3.7.1",
    "jquery-slimscroll": "^1.3.8",
    "jquery-sparkline": "^2.4.0",
    "lodash": "^4.17.21",
    "modernizr": "^3.13.0",
    "moment": "^2.29.4",
    "moment-timezone": "^0.5.43",
    "ng-zorro-antd": "^17.3.0",
    "ng2-file-upload": "^5.0.0",
    "ng2-pdf-viewer": "^10.0.0",
    "ngx-bootstrap": "^11.0.2",
    "ngx-logger": "^5.0.12",
    "ngx-page-scroll": "^11.0.0",
    "ngx-page-scroll-core": "^11.0.0",
    "ngx-papaparse": "^8.0.0",
    "ngx-permissions": "^16.0.1",
    "ngx-stripe": "^17.0.1",
    "roboto-fontface": "^0.10.0",
    "rxjs": "~7.8.0",
    "simple-line-icons": "^2.5.5",
    "tslib": "^2.3.0",
    "url-search-params-polyfill": "^8.2.5",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.0",
    "@angular/cli": "^17.0.0",
    "@angular/compiler-cli": "^17.0.0",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.14.202",
    "@types/node": "^20.10.1",
    "cross-var": "^1.1.0",
    "husky": "^8.0.3",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "2.8.8",
    "pretty-quick": "^3.1.3",
    "typescript": "~5.2.2"
  }
}

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterModule } from 'angular2-toaster';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { NowDirective } from './directives/now/now.directive';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';
import { U2ldatePipe } from '../core/pipe/u2ldate.pipe';
import { SafeHtmlPipe } from './directives/safeHtml/safeHtmlPipe';
import { GetPipe } from './pipe/get.pipe';
// import { SortablejsModule } from 'angular-sortablejs/dist';

import {
  DxDataGridModule,
  DxTemplateModule,
  DxSelectBoxModule,
  DxTextAreaModule,
  DxDateBoxModule,
  DxFormModule,
  DxTabsModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxCheckBoxModule,
  DxNumberBoxModule,
  DxButtonModule,
  DxListModule,
  DxTreeListModule,
  DxPopupModule,
  DxScrollViewModule,
  DxLoadIndicatorModule,
  DxTreeViewModule,
  DxAccordionModule,
  DxDrawerModule,
  DxToolbarModule,
  DxTabPanelModule,
  DxPopoverModule,
  DxSwitchModule,
  DxTagBoxModule,
  DxContextMenuModule,
  DxRadioGroupModule,
  DxTextBoxModule,
  DxBoxModule,
  DxDropDownBoxModule,
  DxFileUploaderModule,
  DxValidationGroupModule,
  DxTooltipModule,
  DxLoadPanelModule,
  DxDropDownButtonModule,
  DxHtmlEditorModule,
  DxSpeedDialActionModule,
  DxPivotGridModule,
  DxChartModule,
} from 'devextreme-angular';

// import { MessageService } from './message/message.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConvertService } from './utils/convert.service';
import { NotifyService } from './message/notify.service';
import { ColorsService } from './colors/colors.service';
import { TabTitleService } from './utils/tabTitle.service';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
// import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { FlotDirective } from './directives/flot/flot.directive';
import { MDatePipe } from '../core/pipe/mdate.pipe';
import { CZonePipe } from '../core/pipe/currentZone.pipe';
import { FormatNamePipe } from '../core/pipe/formatName.pipe';
import { FormatPhonePipe } from '../core/pipe/formatPhone.pipe';
import { FormatInternationalPhonePipe } from '../core/pipe/formatInternationalPhone.pipe';
import { FileUploadModule } from 'ng2-file-upload';
import { AppWithPermissionDirective } from './directives/app.permission';
import { MctShortCutDirective } from './directives/shortCut/shortcut.directive';
import { FixPositionDirective } from './directives/fixposition/fixpostion.directive';
import { HtmlSourcePipe } from '../core/pipe/innerHTML.pipe';
import { AntdModule } from './antd/antd.module';
import { IsNilPipe } from './pipe/isEmpty.pipe';

// https://angular.io/styleguide#!#04-10
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ToasterModule,
    // bootstrap
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    AlertModule.forRoot(),
    // DevExtreme
    DxDataGridModule,
    DxTemplateModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxDateBoxModule,
    DxFormModule,
    DxTabsModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxCheckBoxModule,
    DxNumberBoxModule,
    DxButtonModule,
    DxListModule,
    DxTreeListModule,
    DxPopupModule,
    DxScrollViewModule,
    DxLoadIndicatorModule,
    DxTreeViewModule,
    DxAccordionModule,
    DxDrawerModule,
    DxToolbarModule,
    DxTabPanelModule,
    DxPopoverModule,
    DxSwitchModule,
    DxTagBoxModule,
    DxContextMenuModule,
    DxRadioGroupModule,
    DxTextBoxModule,
    // Permission
    NgxPermissionsModule,
    DxBoxModule,
    DxDropDownBoxModule,
    DxValidationGroupModule,
    DxFileUploaderModule,
    FileUploadModule,
    DxTooltipModule,
    DxLoadPanelModule,
    DxDropDownButtonModule,
    DxHtmlEditorModule,
    DxSpeedDialActionModule,
    DxPivotGridModule,
    DxChartModule,
    AntdModule
  ],
  providers: [ColorsService, NotifyService, ConvertService, TabTitleService],
  declarations: [
    FlotDirective,
    SparklineDirective,
    // EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    MDatePipe,
    CZonePipe,
    FormatNamePipe,
    U2ldatePipe,
    FormatPhonePipe,
    FormatInternationalPhonePipe,
    AppWithPermissionDirective,
    SafeHtmlPipe,
    MctShortCutDirective,
    HtmlSourcePipe,
    IsNilPipe,
    FixPositionDirective,
    GetPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    ToasterModule,
    FlotDirective,
    SparklineDirective,
    // EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    // bootstrap
    BsDropdownModule,
    CollapseModule,
    TabsModule,
    PopoverModule,
    AlertModule,
    // DevExtreme
    DxDataGridModule,
    DxTemplateModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxDateBoxModule,
    DxFormModule,
    DxTabsModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxCheckBoxModule,
    DxNumberBoxModule,
    DxButtonModule,
    DxListModule,
    DxTreeListModule,
    DxPopupModule,
    DxScrollViewModule,
    DxLoadIndicatorModule,
    DxTreeViewModule,
    DxAccordionModule,
    DxDrawerModule,
    DxToolbarModule,
    DxTabPanelModule,
    DxPopoverModule,
    DxSwitchModule,
    DxTagBoxModule,
    DxContextMenuModule,
    DxRadioGroupModule,
    DxTextBoxModule,
    DxPivotGridModule,
    DxChartModule,
    // Permission
    NgxPermissionsModule,
    MDatePipe,
    CZonePipe,
    FormatNamePipe,
    FileUploadModule,
    DxBoxModule,
    DxDropDownBoxModule,
    DxValidationGroupModule,
    DxFileUploaderModule,
    U2ldatePipe,
    FormatPhonePipe,
    FormatInternationalPhonePipe,
    DxTooltipModule,
    DxLoadPanelModule,
    DxDropDownButtonModule,
    AppWithPermissionDirective,
    SafeHtmlPipe,
    DxHtmlEditorModule,
    DxSpeedDialActionModule,
    MctShortCutDirective,
    HtmlSourcePipe,
    FixPositionDirective,
    GetPipe, 
    IsNilPipe,
    AntdModule
  ],
})
// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}

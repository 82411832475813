import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'formatinternationalphone' })
export class FormatInternationalPhonePipe implements PipeTransform {
  constructor() {}

  transform(phone: string): string {
    if (phone) {
      var newNum = '';
      // var matches1 = phone.substr(0,4);
      // var matches2 = phone.substr(4,3);
      // var matches3 = phone.substr(7,3);
      // var matches4 = phone.substr(10,4);
      // newNum = '+'+ matches1 +'-(' + matches2 + ') ' + matches3 + '-' + matches4;

      //var matches1 = phone.substr(0, 4);
      var matches2 = phone.substr(0, 3);
      var matches3 = phone.substr(3, 3);
      var matches4 = phone.substr(6, 4);
      newNum = '(' + matches2 + ') ' + matches3 + '-' + matches4;
      return newNum;
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import { JsonServiceClient } from '@servicestack/client';
import { User, Workspace } from '../models/user.model';

import { environment } from '../../../environments/environment';
import {
  GetWorkspaceListRequest,
  GetWorkspaceByWorkspaceCodeRequest,
  GetUserPermissionsByUserIdRequest,
} from '../models/servicemodel/users.api';
import { NotifyService } from '../../shared/message/notify.service';
import { NgxPermissionsService } from 'ngx-permissions';
// import { GetUserPermissionByUserIdRequest } from '../models/servicemodel/permission.api';
import { AuthAppService } from './auth.app';
import { CacheService } from '../services/cacheService';

@Injectable()
export class WorkspaceGuardService {
  private appToken: string;
  public currentWorkspace: BehaviorSubject<Workspace> = new BehaviorSubject<Workspace>(null);
  public workspaceReady: Subject<Workspace[]> = new Subject<Workspace[]>();
  public AllowedWorkspaces = [];
  public AllowedOrgIds = [];
  public currentUserId = 0;
  public currentWorkspceId = '';
  public currentRequestCustomerId = 0;
  public currentWorkspaceCode = '';
  public currentOrgMember;

  private activeWorkspace: Workspace;
  accesscode: string;
  permissionData: any = {};
  cache: any;
  constructor(
    private authApp: AuthAppService,
    private message: NotifyService,
    private permission: NgxPermissionsService,
    private route: Router,
  ) {
    authApp.apiReady.subscribe(t => (this.appToken = t));

    this.cache = new CacheService();
  }

  public async SetUserWorkspace(user: User, code, islogin) {
    if (user) {
      this.currentUserId = user.UserId;
      this.accesscode = user.accessCode;
      const data = await this.getWorkspaceByUser(user);
      if (!data && data.length == 0) {
        return null;
      } else {
        this.AllowedWorkspaces = data;
        await this.setCurrentWorkspaceCode(code, islogin);
        // this.setCurrentWorkspaceId(data[0].id);
        this.workspaceReady.next(data);
        return data;
      }
    } else {
      this.currentUserId = 0;
      this.AllowedWorkspaces = [];
      this.currentWorkspace.next(null);
      return null;
    }
  }

  public async checkUserPermission(user: User, code) {
    if (user) {
      this.currentUserId = user.UserId;
      this.accesscode = user.accessCode;
      const data = await this.getWorkspaceByUser(user);
      if (!data && data.length == 0) {
        return false;
      } else {
        const w = data.find(x => x.Code.toLowerCase() == code.toLowerCase());
        if (w) {
          const permission = await this.getPermissionByUser(w.Id, this.currentUserId);
          const dataValue = permission && permission.length > 0 ? true : false;
          return dataValue;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  private async getWorkspaceByUser(user) {
    const cashkey = 'GetWorkspaceListRequest_' + user.UserId;
    let response = this.cache.getCacheData(cashkey);
    if (!response) {
      const request = new GetWorkspaceListRequest();
      request.UserId = user.UserId;
      request.CustomerId = user.CustomerId;
      const client = this.getCareWaitApiClient();
      response = await client.get(request);
      this.cache.setCacheData(cashkey, response);
    }

    if (response.IsSuccess) {
      return response.Data;
    } else {
      this.message.showError(response.ErrMsg);
      return 0;
    }
  }

  public async getWorkspaceByWorkspaceCode(workspacecode) {
    const request = new GetWorkspaceByWorkspaceCodeRequest();
    request.WorkspaceCode = workspacecode;
    const client = this.getCareWaitApiClient();
    const response = await client.get(request);
    if (response.IsSuccess) {
      return response.Data;
    } else {
      this.message.showError(response.ErrMsg);
      return 0;
    }
  }

  public getCurrentWorkspace() {
    return this.activeWorkspace;
  }

  public setCurrentWorkspaceId(workspaceId: number) {
    const w = this.AllowedWorkspaces.find(x => x.Id == workspaceId);
    if (w) {
      return this.setCurrentWorkspace(w);
    }
    return null;
  }

  public setCurrentWorkspaceCode(code: string, islogin = false) {
    const w = this.AllowedWorkspaces.find(x => x.Code.toLowerCase() == code.toLowerCase());
    if (w) {
      return this.setCurrentWorkspace(w, islogin);
    }
    return null;
  }

  public async setCurrentWorkspace(w: Workspace, islogin = false) {
    if (w) {
      if (w.Id == this.currentWorkspceId) {
        if (islogin) {
          // console.log('islogin',islogin);
          const userid = this.currentUserId;
          const permission = await this.getPermissionByUser(w.Id, userid);
          this.permission.flushPermissions();
          this.permission.loadPermissions(permission);
          // console.log('==== islogin get permission', permission.length);
        }
        return w;
      } else {
        this.activeWorkspace = w;
        this.currentWorkspceId = w.Id;
        this.currentRequestCustomerId = w.CustomerId;
        this.currentWorkspaceCode = w.Code;
        const userid = this.currentUserId;
        const permission = await this.getPermissionByUser(w.Id, userid);
        this.permission.flushPermissions();
        this.permission.loadPermissions(permission);
        // console.log(11111111);
        // console.log('====  get permission', permission.length);
        this.currentWorkspace.next(w);
        return w;
      }
    } else {
      throw new Error('Workspace is null');
    }
  }

  public getCareWaitApiClient(refUrl?: string): JsonServiceClient {
    const baseUrl = environment.apigateway_url;
    const apikey = environment.apigateway_key ? environment.apigateway_key : '';
    let url = baseUrl + 'stanfordcwv2';
    if (!url.endsWith('/')) {
      url += '/';
    }
    if (refUrl) {
      url += refUrl;
    }
    const jc = new JsonServiceClient(url);
    jc.bearerToken = this.accesscode;
    jc.headers.append('X-CC-AppId', apikey);
    jc.headers.append('X-CC-AppToken', this.appToken);
    return jc;
  }

  private async getPermissionByUser(workspaceId, userid) {
    const key = 'GetUserPermissionsByUserIdRequest_' + workspaceId + '_' + userid;
    // console.log('==== get permission data: ', key);
    // let response = this.permissionData[key];
    let response = this.cache.getCacheData(key);
    // debugger;
    if (!response) {
      const request = new GetUserPermissionsByUserIdRequest();
      request.RequestUserId = userid;
      request.RequestWorkspaceId = workspaceId;
      const client = this.getCareWaitApiClient();
      response = await client.get(request);
      this.permissionData[key] = response;
      this.cache.setCacheData(key, response);
    }
    if (response.IsSuccess) {
      const permissions = response.Data ? response.Data : [];
      return permissions;
    } else {
      return [];
    }
  }

  public async hasPermission(entityTypeCode: string, type, isSplice = false) {
    let hasPermission = false;
    let codes = [];
    if (type == 'view') {
      codes.push('carewait.entity.' + entityTypeCode);
      codes.push('carewait.entity.' + entityTypeCode + '.view');
    } else if (type == 'edit') {
      codes.push('carewait.entity.' + entityTypeCode);
      codes.push('carewait.entity.' + entityTypeCode + '.edit');
    } else if (type == 'add') {
      codes.push('carewait.entity.' + entityTypeCode);
      codes.push('carewait.entity.' + entityTypeCode + '.add');
    } else if (type == 'delete') {
      codes.push('carewait.entity.' + entityTypeCode);
      codes.push('carewait.entity.' + entityTypeCode + '.delete');
    } else if (type == 'customlist') {
      codes.push('carewait.entity.' + entityTypeCode + '.customlist');
    } else if (type == 'sharelist') {
      codes.push('carewait.entity.cw_app_application.sharelist');
    } else if (type == 'admin') {
      codes.push(entityTypeCode);
    }

    if (isSplice) {
      codes.push('carewait.entity.' + entityTypeCode);
      codes.push('carewait.entity.' + entityTypeCode + type);
    }
    hasPermission = await this.permission.hasPermission(codes);
    return hasPermission;
  }

  public async getAnonyWorkspaceByWorkspaceCode(workspacecode) {
    const postData = new GetWorkspaceByWorkspaceCodeRequest();
    postData.WorkspaceCode = workspacecode;
    const client = this.getAnonyClient();
    const response = await client.get<any>('', postData);
    if (response.IsSuccess) {
      return response.Data;
    } else {
      return 0;
    }
  }

  public getAnonyClient(refUrl?: string): JsonServiceClient {
    const baseUrl = environment.apigateway_url;
    const apikey = environment.apigateway_key ? environment.apigateway_key : '';
    let url = baseUrl + 'cwv2_getworkspacebycode_anonymous';
    if (!url.endsWith('/')) {
      url += '/';
    }
    if (refUrl) {
      url += refUrl;
    }
    const jc = new JsonServiceClient(url);
    jc.bearerToken = this.accesscode;
    jc.headers.append('X-CC-AppId', apikey);
    jc.headers.append('X-CC-AppToken', this.appToken);
    return jc;
  }
}

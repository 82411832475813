import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';

import { environment } from '../../../environments/environment';
import { User } from '../models/user.model';
import { LogService } from '../log/log.service';
import { NgxPermissionsService } from 'ngx-permissions';

import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { JsonServiceClient } from '@servicestack/client';
import { WorkspaceGuardService } from './workspace.guard.service';
import { TranslationPipe } from '../pipe/translationPipe';
import { EntityCacheService, ReportCacheService } from '../services/cacheService';

export class AuthResponse {
  Token: string;
  IsSuccess: boolean;
  ErrCode: number;
  ErrMsg: string;
  Data: User;
}
@Injectable()
export class AuthService {
  public currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public affiliationData: BehaviorSubject<number> = new BehaviorSubject<number>(0); // add by wjw to update family's parent/employee id to emit

  private apiSigninUrl = environment.apigateway_url + 'signin/';

  // local storage
  private userTokenKey = 'cc_token';
  private userTokenTime = 'cc_token_t';
  private _remeberme = false;
  private expired = '0';
  public sessionId: string;
  promiseReturn: any;

  constructor(
    private router: Router,
    private workspace: WorkspaceGuardService,
    private translater: TranslationPipe,
    private permissionsService: NgxPermissionsService,
    private logger: LogService,
    private entityCacheService: EntityCacheService,
    private reportCacheService: ReportCacheService,
  ) {}

  public setAffiliationData(data: number = 0): void {
    this.affiliationData.next(data);
  }

  public getAffiliationData() {
    return this.affiliationData.getValue();
  }

  private getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return '';
  }

  public delayTokenTime(expiredMinites = 16 * 60) {
    const ftm = 'YYYYMMDDHHmmss';
    let debugTime = 0;
    if (location.search && location.search.indexOf('delaytime=') > -1) {
      debugTime = parseInt(this.getQueryVariable('delaytime'));
      if (debugTime > 0) {
        expiredMinites = debugTime;
      }
    }
    const now = moment();
    this.expired = now.clone().add(expiredMinites, 's').format(ftm);
    // if (this._remeberme) {
    //   this.expired = moment().add(expiredDays, 'm').format(ftm);
    // }

    const initKey = this.userTokenTime;
    const initLoginTime = localStorage.getItem(initKey);
    if (!initLoginTime || this.expired <= initLoginTime) {
      // console.log('==== delay session token: ', expiredMinites, this.expired, now.clone().add(expiredMinites, 's').format('YYYY-MM-DD HH:mm:ss'));
      const u = this.getCurrentUser();
      if (u && u.UserId > 0) {
        const session = {
          sessionId: this.sessionId,
          token: this.getCurrentUser().accessCode,
          expired: this.expired,
        };
        const token = btoa(JSON.stringify(session)); // Base64.encode(JSON.stringify(session));
        this.clearSessionStorage();
        localStorage.setItem(this.userTokenKey, token);
        if (!initLoginTime) {
          localStorage.setItem(initKey, now.clone().add(1, 'd').format(ftm));
        }
      }
    } else {
    }
  }

  public setCurrentUser(newUser: User, rememberme: boolean, expiredDays = 5): void {
    if (newUser && newUser.UserId > -1) {
      const u = { userId: newUser.UserId, LogonName: newUser.LogonName };
      sessionStorage.setItem('rg4js_logger', JSON.stringify(u));
    }
    this.currentUser.next(newUser);
    // SessionId
    this.sessionId = uuid();
    this.delayTokenTime();
    // // this.permissionsService.loadPermissions(newUser.Roles);
    // // this.permissionsService.addPermission(newUser.permissions);
    // this._remeberme = rememberme;

    // this.expired = moment().add(1, 'days').format('YYYYMMDD');
    // if (this._remeberme) {
    //   this.expired = moment().add(expiredDays, 'days').format('YYYYMMDD');
    // }
    // const session = {
    //   sessionId: this.sessionId,
    //   token: newUser.accessCode,
    //   expired: this.expired
    // };
    // const token = btoa(JSON.stringify(session)); // Base64.encode(JSON.stringify(session));
    // // sessionStorage.clear();
    // this.clearSessionStorage();
    // localStorage.setItem(this.userTokenKey, token);
    // // document.cookie = this.userTokenKey + '=' + token + ';path=/';
  }

  public getCurrentUser(): User {
    return this.currentUser.getValue();
  }

  public logout(wkcode, isNotRedirect = false): void {
    localStorage.removeItem('family_user');
    const user = this.currentUser.getValue();
    this.currentUser.next(null);
    this.logger.debug('remove current user');
    localStorage.removeItem(this.userTokenKey);
    sessionStorage.removeItem(this.userTokenKey);
    localStorage.removeItem('RequestCustomerId');
    localStorage.removeItem('menuLists');
    localStorage.removeItem('allDefaultQueryList');
    sessionStorage.removeItem('rg4js_logger');
    localStorage.removeItem(this.userTokenTime);

    this.entityCacheService.clearCacheData();
    this.reportCacheService.clearCacheData();
    this.clearOtherSessionStorage();

    // document.cookie = this.userTokenKey + '=' + ';path=/';
    const wkcodeurl = wkcode ? '/' + wkcode : '';
    const isParent = location.href.indexOf('/parent') > -1;
    const isStanford = location.href.indexOf('/family') > -1 && location.href.indexOf('/Wlo') == -1;
    let returnUrl = window.location.origin;
    if (!isParent) {
      if (isStanford) {
        returnUrl = returnUrl + wkcodeurl + '/family/login';
        // this.router.navigate([wkcodeurl+'/family/login']);
      } else {
        returnUrl = returnUrl + wkcodeurl + '/home/login';
        // this.router.navigate([wkcodeurl+'/home/login']);
      }
    } else {
      returnUrl = returnUrl + wkcodeurl + '/parent/login';
      // this.router.navigate([wkcodeurl+'/parent/login']);
    }

    let url = environment.samlSetting.samlSloUrl + '?returnUrl=' + returnUrl;

    let cutUpUrl = new URL(location.href);
    cutUpUrl.searchParams.delete('AccessCode');
    cutUpUrl.searchParams.delete('idp');

    if (!isNotRedirect) {
      if (returnUrl && returnUrl.indexOf('?returnUrl=') === -1) {
        // url += '?returnUrl=' + encodeURIComponent(location.href.replace(location.origin, ''));
        url += '?returnUrl=' + encodeURIComponent(cutUpUrl.toString().replace(location.origin, ''));
      }
      window.location.href = url;
    }
  }

  onlyToLogin(wkcode) {
    const wkcodeurl = wkcode ? '/' + wkcode : '';
    const isParent = location.href.indexOf('/parent') > -1;
    const isStanford = location.href.indexOf('/family') > -1 && location.href.indexOf('/Wlo') == -1;
    let returnUrl = window.location.origin;
    if (!isParent) {
      if (isStanford) {
        returnUrl = returnUrl + wkcodeurl + '/family/login';
      } else {
        returnUrl = returnUrl + wkcodeurl + '/home/login';
      }
    } else {
      returnUrl = returnUrl + wkcodeurl + '/parent/login';
    }

    let url = returnUrl;
    url += '?returnUrl=' + encodeURIComponent(location.href.replace(location.origin, ''));
    window.location.href = url;
  }

  public getToken(): string {
    this.logger.debug('getToken()');
    try {
      const token = localStorage.getItem(this.userTokenKey);
      if (token) {
        const code = atob(token); // Base64.decode(token);
        this.logger.debug(code);
        const session = JSON.parse(code);
        const today = moment().format('YYYYMMDDHHmmss');
        const initLimitTime = localStorage.getItem(this.userTokenTime);
        if (session && session.expired >= today && initLimitTime && initLimitTime > today) {
          return session.token;
        }
      }
      return undefined;
    } catch (error) {
      this.logger.error(error);
    }
  }

  public isAuthenticated(): boolean {
    const u = this.currentUser.getValue();
    if (!u) {
      // get the token
      this.logger.debug('isAuthenticated false');
      return false;
    } else {
      return true;
    }
  }

  public getAuthClient(): JsonServiceClient {
    const url = environment.apigateway_url + 'cc_auth';
    const jc = new JsonServiceClient(url);
    return jc;
  }

  public getClient(): JsonServiceClient {
    const jc = new JsonServiceClient(this.apiSigninUrl);
    return jc;
  }

  private checkAccountType(newUser: User) {
    /// TODO: carewait to check user's authorization
    // const noPermissionMsg = this.translater.transform('YOU_HAVE_NO_PERMISSION_TO_ACCESS_THIS_PAGE');
    // if (!newUser) {
    //   throw (new Error(noPermissionMsg));
    // } else {
    //   if (newUser && (newUser.AccountType + '') !== '2') {
    //     throw (new Error(this.translater.transform('NO_TPARENT')));
    //   }
    //   // throw (new Error(noPermissionMsg));
    // }
  }

  private async loginAsync(client, req) {
    if (!this.promiseReturn) {
      // console.log('==== loginAsync data: none ');
      this.promiseReturn = Promise.resolve(client.postToUrl('/', req));
      return await this.promiseReturn;
    } else {
      // console.log('==== loginAsync: ');
      return await this.promiseReturn;
    }
  }

  public async login(
    name: string,
    password: string,
    wkcode: string,
    isparent: boolean,
    remeberme: boolean,
  ): Promise<User> {
    const client = this.getAuthClient();
    client.setCredentials(name, password);

    const isStanfordParent =
      location.href.indexOf('/family') > -1 && location.href.indexOf('/Wlo') == -1;
    let meta = {};
    if (isStanfordParent) {
      meta = {
        WorkspaceCode: wkcode,
        AccountType: 2,
      };
    } else {
      meta = {
        WorkspaceCode: wkcode,
      };
    }

    const req = {
      Idp: 'carewait',
      Meta: meta,
      Provider: 'base',
      // WorkspaceCode: wkcode,
      createResponse() {
        return new AuthResponse();
      },
    };
    localStorage.removeItem(this.userTokenTime);
    const data = await client.postToUrl('/', req); //  await client.postToUrl('/', req); // await client.postToUrl('/', req);
    if (data && data.IsSuccess) {
      const user = data.Data;
      user.accessCode = data.Token;
      let canLogin = true;
      if (!isparent) {
        const isPermission = await this.workspace.checkUserPermission(user, wkcode);
        if (!isPermission) {
          canLogin = false;
        }
      }
      if (!canLogin) {
        throw new Error('Please log in with your user account');
      } else {
        // this.checkAccountType(user);
        user.accessCode = data.Token;
        const wkid = await this.workspace.SetUserWorkspace(user, wkcode, true);
        if (wkid) {
          this.setCurrentUser(user, this._remeberme);
          return user;
        } else {
          throw new Error('You have no permission to access this service.');
        }
      }
    } else {
      throw new Error(data.ErrMsg);
    }
  }

  public async loginWithToken(token = '', isForceRefresh = false, wkcode = ''): Promise<User> {
    this.logger.debug(`Login with token: ${token}`);
    if (!wkcode) {
      if (location.pathname && location.pathname.split('/')[1]) {
        wkcode = location.pathname.split('/')[1];
      }
    }

    const isStanfordParent =
      location.href.indexOf('/family') > -1 && location.href.indexOf('/Wlo') == -1;
    let meta = {};
    if (isStanfordParent) {
      meta = {
        WorkspaceCode: wkcode,
        AccountType: 2,
      };
    } else {
      meta = {
        WorkspaceCode: wkcode,
      };
    }

    if (!token) {
      token = this.getToken();
      this.logger.debug(`Login with token 2: ${token}`);
    }
    if (token) {
      const client = this.getAuthClient();
      client.bearerToken = token;
      const req = {
        Idp: 'carewait',
        Meta: meta,
        Provider: 'accesscode',
        IsForceRefresh: isForceRefresh,
        createResponse() {
          return new AuthResponse();
        },
      };
      const noPermissionMsg = this.translater.transform(
        'YOU_HAVE_NO_PERMISSION_TO_ACCESS_THIS_PAGE',
      );
      try {
        const data = await client.postToUrl('/', req); // await client.postToUrl('/', req);
        // console.log(data);
        if (data && data.IsSuccess) {
          const user = data.Data;
          // this.checkAccountType(user);
          user.accessCode = data.Token;
          // const list = await this.canAccessApp(3120, data.Token);
          // if (list && list.length > 0) {
          //   user.permissions = list;
          // }
          const wkid = await this.workspace.SetUserWorkspace(user, wkcode, true);
          if (wkid) {
            this.setCurrentUser(user, this._remeberme);
            return user;
          } else {
            throw new Error('You have no permission to access this service.');
          }
        } else {
          throw new Error(noPermissionMsg);
        }
      } catch (error) {
        let err = noPermissionMsg;
        if (error && error.responseStatus && error.responseStatus.errorCode === '401') {
          if (error.responseStatus.message === 'AccessCode is required') {
            err = 'auto_check_token_error'; // auto to check token, without error...
          }
        }
        throw new Error(err);
      }
    }
    // return null;
  }

  async canAccessApp(user: User, token: string) {
    const url =
      environment.apigateway_url + 'auth/user/permissionsbyuserid?RequestUserId=' + user.UserId;
    const jc = new JsonServiceClient(url);
    jc.bearerToken = token;
    const response = await jc.get<any>(url);
    if (response.IsSuccess) {
      // console.log(response.Data);
      return response.Data;
    } else {
      return [];
    }
  }

  public checkInvitationCode() {
    let returnvalue = '';
    let query = decodeURI(location.search.substring(1));
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] == 'InvitationCode') {
        returnvalue = pair[1];
      }
    }
    return returnvalue;
  }

  getWkCode(wkcodeurl) {
    let w = 'Stanford';
    if (!wkcodeurl || wkcodeurl === '/') {
      w = window['wkCode'] ? window['wkCode'] : 'Stanford';
    } else {
      w = '/' + wkcodeurl;
    }
    return w;
  }

  public async checkPermission(code, type, wkcode) {
    let hasPermission = await this.workspace.hasPermission(code, type);
    if (!hasPermission) {
      // const wkcodeurl = wkcode ? "/" + wkcode : '';
      const w = this.getWkCode(wkcode);
      this.router.navigate([w + '/pages/401']);
    }
  }

  public goTo404(wkCode) {
    // const wkcodeurl = wkCode ? "/" + wkCode : '';
    const w = this.getWkCode(wkCode);
    this.router.navigate([w + '/pages/404']);
  }

  public clearSessionStorage() {
    let notClearkey = [
      'viewsmartliststate_of_',
      'viewsmartlistquerymodel_',
      'vieweroffliststate_of_',
      'ct_entityapplication',
      'viewerjobliststate',
      'vieweradminreport_of_',
      'vieweradminsiteandpriority_of_',
      'vieweradminsmartlist_of_',
      'viewersiteofferlist_of_',
      'viewerstate_of_',
      'ct_entity_',
      'viewadminentity_of_',
      'viewadmindictionary_of_',
      'viewadminmapping_of_',
      'viewadminmenu_of_',
      'viewadminmessageTemplate_of_',
      'viewadminmessageTemplateDetail_of_',
      'viewadminmessageTemplateDetailStatus_of_',
      'viewsmartlistdefaultquerymodel_',
      'viewadminofferTemplate_of_',
    ];
    let remmoveSession = [];
    if (sessionStorage && sessionStorage.length > 0) {
      for (var i = 0, len = sessionStorage.length; i < len; ++i) {
        const k = sessionStorage.key(i);
        const length = notClearkey.filter(f => k.indexOf(f) > -1).length;
        if (k && length == 0) {
          remmoveSession.push(k);
        }
      }
      remmoveSession.forEach(item => {
        sessionStorage.removeItem(item);
      });
    }
  }

  public clearOtherSessionStorage() {
    let notClearkey = [
      'viewsmartliststate_of_',
      'viewsmartlistquerymodel_',
      'vieweroffliststate_of_',
      'ct_entityapplication',
      'viewerjobliststate',
      'vieweradminreport_of_',
      'vieweradminsiteandpriority_of_',
      'vieweradminsmartlist_of_',
      'viewersiteofferlist_of_',
      'viewerstate_of_',
      'ct_entity_',
      'viewadminentity_of_',
      'viewadmindictionary_of_',
      'viewadminmapping_of_',
      'viewadminmenu_of_',
      'viewadminmessageTemplate_of_',
      'viewadminmessageTemplateDetail_of_',
      'viewadminmessageTemplateDetailStatus_of_',
      'viewsmartlistdefaultquerymodel_',
    ];
    let remmoveSession = [];
    if (sessionStorage && sessionStorage.length > 0) {
      for (var i = 0, len = sessionStorage.length; i < len; ++i) {
        const k = sessionStorage.key(i);
        const length = notClearkey.filter(f => k.indexOf(f) > -1).length;
        if (k && length > 0) {
          remmoveSession.push(k);
        }
      }
      remmoveSession.forEach(item => {
        sessionStorage.removeItem(item);
      });
    }
  }
}

import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'formatphone' })
export class FormatPhonePipe implements PipeTransform {
  constructor() {}

  transform(phone: string): string {
    if (phone) {
      var newNum = '';
      if (phone.length == 11) {
        var reg = /^(\d{3})(\d{4})(\d{4})$/;
        var matches = reg.exec(phone);
        if (matches && matches.length == 4) {
          newNum = '(' + matches[1] + ') ' + matches[2] + '-' + matches[3];
        }
      } else if (phone.length == 10) {
        var reg = /^(\d{3})(\d{3})(\d{4})$/;
        var matches = reg.exec(phone);
        if (matches && matches.length >= 4)
          newNum = '(' + matches[1] + ') ' + matches[2] + '-' + matches[3];
      } else if (phone.length == 9) {
        var reg = /^(\d{3})(\d{3})(\d{3})$/;
        var matches = reg.exec(phone);
        if (matches && matches.length >= 4)
          newNum = '(' + matches[1] + ') ' + matches[2] + '-' + matches[3] + '_';
      } else if (phone.length == 8) {
        var reg = /^(\d{3})(\d{3})(\d{2})$/;
        var matches = reg.exec(phone);
        if (matches && matches.length >= 4)
          newNum = '(' + matches[1] + ') ' + matches[2] + '-' + matches[3] + '__';
      } else if (phone.length == 7) {
        var reg = /^(\d{3})(\d{3})(\d{1})$/;
        var matches = reg.exec(phone);
        if (matches && matches.length >= 4)
          newNum = '(' + matches[1] + ') ' + matches[2] + '-' + matches[3] + '___';
      } else if (phone.length == 6) {
        var reg = /^(\d{3})(\d{3})$/;
        var matches = reg.exec(phone);
        if (matches && matches.length >= 3)
          newNum = '(' + matches[1] + ') ' + matches[2] + '-' + '____';
      } else if (phone.length == 5) {
        var reg = /^(\d{3})(\d{2})$/;
        var matches = reg.exec(phone);
        if (matches && matches.length >= 3)
          newNum = '(' + matches[1] + ') ' + matches[2] + '_' + '-' + '____';
      } else if (phone.length == 4) {
        var reg = /^(\d{3})(\d{1})$/;
        var matches = reg.exec(phone);
        if (matches && matches.length >= 3)
          newNum = '(' + matches[1] + ') ' + matches[2] + '__' + '-' + '____';
      } else if (phone.length == 3) {
        var reg = /^(\d{3})$/;
        var matches = reg.exec(phone);
        if (matches && matches.length >= 2) newNum = '(' + matches[1] + ') ' + '___' + '-' + '____';
      } else if (phone.length == 2) {
        var reg = /^(\d{2})$/;
        var matches = reg.exec(phone);
        if (matches && matches.length >= 2)
          newNum = '(' + matches[1] + '_' + ') ' + '___' + '-' + '____';
      } else if (phone.length == 1) {
        var reg = /^(\d{1})$/;
        var matches = reg.exec(phone);
        if (matches && matches.length >= 2)
          newNum = '(' + matches[1] + '__' + ') ' + '___' + '-' + '____';
      } else {
        newNum = phone;
      }
      return newNum;
    }
  }
}

import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'formatname' })
export class FormatNamePipe implements PipeTransform {
  constructor() {}

  transform(
    firstName: string,
    lastName: string,
    middleName: string = '',
    isList: boolean = false,
  ): string {
    let name = '--';
    if (firstName) {
      name = firstName;
      if (lastName) {
        name += ' ' + lastName;
      }
    } else {
      if (lastName) {
        name = lastName;
      }
    }
    if (middleName) {
      name += ' ' + middleName;
    }
    if (name) {
      name = name.trim();
      if (!name) {
        name = '--';
      }
    }
    return name;
  }
}

// formatName: function (firstName, lastName) {
//     var name = "--";
//     if (firstName) {
//         name = firstName;
//         if (lastName) {
//             name += (" " + lastName);
//         }
//     } else {
//         if (lastName) {
//             name = lastName;
//         }
//     }
//     return name;
// },
// formatNameInList: function (firstName, lastName) {
//     var name = "--";
//     if (lastName) {
//         name = lastName;
//         if (firstName) {
//             name += (", " + firstName);
//         }
//     } else {
//         if (firstName) {
//             name = firstName;
//         }
//     }
//     return name;
// },
// formatNamewithMiddleInList: function (firstName, lastName, middlename) {
//     var name = "--";
//     if (lastName) {
//         name = lastName;
//         if (firstName) {
//             name += (", " + firstName);
//         }
//     } else {
//         if (firstName) {
//             name = firstName;
//         }
//     }
//     if (middlename) {
//         name += (" " + middlename);
//     }
//     return name;
// },
// formatAddress: function (city, state, zip) {
//     var name = " ";
//     if (city) {
//         name = city;
//         if (state) {
//             name += (", " + state);
//         }
//     } else {
//         if (state) {
//             name = state;
//         }
//     }
//     if (zip) {
//         name += " " + zip;
//     }
//     return name;
// }

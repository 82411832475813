import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'translation' })
export class TranslationPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: string, arg: Object = null): string {
    // this.translate.get('SUBSCRIPTION')['value']
    if (arg) {
      return this.translate.instant(value, arg);
    }
    return this.translate.instant(value);
  }
}

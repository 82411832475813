import { IReturn, Response, BaseRequest } from '../../carecloud/common.api';

export class CareWaitBaseRequest extends BaseRequest {
  RequestCustomerId: number;
  RequestWorkspaceId: string;
}

// [Route("/core/workspace/of/user/{RequestUserId}", "GET")]
export class GetUserWorkspaceListByUserIdRequest
  extends BaseRequest
  implements IReturn<Response<any>>
{
  RequestUserId: number;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetUserWorkspaceListByUserIdRequest';
  }
}

export class GetWorkspaceListRequest extends BaseRequest implements IReturn<Response<any>> {
  UserId: number;
  CustomerId: number;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetWorkspaceListRequest';
  }
}

// [Route("/core/workspace/update/{Id}", "PUT")]
export class PutWorkspaceRequest extends BaseRequest implements IReturn<Response<any>> {
  Id: number;
  UpdateFields: any;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'PutWorkspaceRequest';
  }
}

// [Route("/workspace/by-WorkspaceCode/{WorkspaceCode}", "GET")]
export class GetWorkspaceByWorkspaceCodeRequest
  extends BaseRequest
  implements IReturn<Response<any>>
{
  WorkspaceCode: string;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetWorkspaceByWorkspaceCodeRequest';
  }
}

// [Route("/auth/user/permissionsbyuserid")]
export class GetUserPermissionsByUserIdRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<string[]>>
{
  RequestUserId: number;
  createResponse() {
    return new Response<any>();
  }
  getTypeName() {
    return 'GetUserPermissionsByUserIdRequest';
  }
}

// [Route("/user/validate/password")]
export class ValidateUserPasswordRequest
  extends CareWaitBaseRequest
  implements IReturn<ValidateUserResponse>
{
  RequestUserId: number;
  Password: string;
  NoEncrypted: boolean; // add by wjw for NEW　UI cannot encypted at forend @ 05/09/2019
  createResponse() {
    return new ValidateUserResponse();
  }
  getTypeName() {
    return 'ValidateUserPasswordRequest';
  }
}
export class ValidateUserResponse extends Response<boolean> {}

// [Route("/user/adminchangepassword", "POST")]
export class AdminChangePasswordRequest
  extends CareWaitBaseRequest
  implements IReturn<Response<boolean>>
{
  RequestUserId: number;
  NewPassword: string;
  NeedEncrypted: boolean;
  createResponse() {
    return new Response<boolean>();
  }
  getTypeName() {
    return 'AdminChangePasswordRequest';
  }
}
